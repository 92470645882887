import React, { useEffect } from "react"
import { textAppearAnimationAll } from "./textAppearAnimation"

const Hero = ({ fields }) => {

  useEffect(() => {
    textAppearAnimationAll("h1");
  }, []);

  let { heroTitle, heroTitleItalic, heroContent } = fields;

  return (
    <div className="container">
      <div className={"hero-section"}>
        <div className="hero-section-title">
          <h1 className={heroContent ? "padding-bottom-40" : "padding-bottom-64-hero-no-content"}>
            {heroTitle}
            {heroTitleItalic ? <div style={{display:'inline-block'}} className={'italic'}>{heroTitleItalic}</div> : null}
          </h1>
        </div>
        {heroContent ?
        <div className={"hero-section-content padding-bottom-80"}>
          <p dangerouslySetInnerHTML={{__html: heroContent}}/>
        </div> : null }
      </div>
    </div>
  )
}

export default Hero
