import React, {useEffect} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../components/hero"
import FullPageVide from "../components/full-page-video"

import { textAppearAnimationAll } from "../components/textAppearAnimation"

const AboutPage = ({ data, location }) => {

  const siteTitle = data.site.siteMetadata.title
  const content = data.wpPage

    useEffect(() => {
        textAppearAnimationAll("h2");
        textAppearAnimationAll("h3");
    }, []);

  return (
    <Layout title={siteTitle} isHomePage={true} page={'about'}>
      <Seo title="About" slug="about"  />

      <Hero fields={content.customFieldsHero} />
      <FullPageVide video={content.customFieldsFullscreenVideo.vimeoId} />

      <div className="container">
        <h2 className={"padding-bottom-40 h2-about"}>
          {content.customFieldsAbout.middleSectionTitle}
        </h2>

        <div className={"content padding-bottom-200"} dangerouslySetInnerHTML={{__html: content.customFieldsAbout.middleSectionContent}}/>

        {/*<h2 className={"padding-bottom-40"}>*/}
        {/*  Meet the team.*/}
        {/*</h2>*/}

        {/*<div className={"team padding-bottom-80"}>*/}
        {/*  {content.customFieldsAbout.team.map(({name, surname, position, image}, index) => {*/}
        {/*    return(*/}
        {/*      <div className={"person"} key={`person-${index}`}>*/}
        {/*        <div className={"wp-block-image"}>*/}
        {/*            <ImageWithAnimation src={image.localFile.childImageSharp.fluid} />*/}
        {/*        </div>*/}
        {/*          <div className={"description"}>*/}
        {/*          <h3 className={"name"}>{name}</h3>*/}
        {/*          <h3 className={"surname"}>{surname}</h3>*/}
        {/*          <TextRepeater text={position} repeat={30} />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</div>*/}

      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        wpPage(slug: { eq: "about" }) {
            title
            slug
            customFieldsFullscreenVideo {
                vimeoId
            }
            customFieldsHero {
                heroTitle
                heroTitleItalic
                heroContent
            }
            customFieldsAbout {
                middleSectionTitle
                middleSectionContent
                team {
                    name
                    surname
                    position
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxHeight: 440) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
