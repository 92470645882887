import React, { useEffect, useState } from "react"
import { gsap } from "gsap"
import Player from "@vimeo/player"

import {
  BrowserView,
  MobileView,
  isBrowser,
} from "react-device-detect"

let player = null
let videoIsPlaying = false
const FullPageVide = ({ video, padding = 'padding-bottom-24' }) => {

  // const [videoIsPlaying, setPlayVideo] = useState(false);
  const [dom, updateDom] = useState(false)
  const [initialize, setInitialize] = useState(false);

  useEffect(() => {
    if (!initialize) {

      setInitialize(true);
      let iframe = document.querySelector("#vimeo-video-embed")

      const storeSRC = iframe.dataset.src;
      delete iframe.dataset.src;
      iframe.src = storeSRC;

      player = new Player(iframe);

      if (isBrowser) {

        player.play()
        player.setVolume(0)
        videoIsPlaying = false


        // Variables
        // ************

        var button = document.querySelector(".toggle-music-button")
        var followArea = document.querySelectorAll(".full-page-video-section")
        var page = document.querySelector(".vimeo-embed")

        // Follow Animation
        // ************
        var moveCircle = (e) => {
          gsap.to(button, 0.25, {
            css: {
              left: e.clientX,
              top: e.clientY
            }
          })
        }

        // Event Listeners
        // ************

        page.addEventListener("mousemove", moveCircle)
        followArea.forEach(function(el) {
          el.addEventListener("mouseover", (e) => {
            gsap.to(button, 0, {
              css: {
                transform: `translate(-50%, -50%)`,
                left: e.clientX,
                top: e.clientY
              }
            })
            gsap.to(button, 0, {
              scale: 1,
              autoAlpha: 1
            })

          })
          el.addEventListener("mouseout", () => {
            gsap.to(button, 0, {
              scale: 0,
              autoAlpha: 0
            })
          })

          el.addEventListener("touchstart", () => {
            //console.log("touchstart")
          })

          el.addEventListener("touchend", () => {
            //console.log("touchend")
          })

          el.addEventListener("mousedown", () => {

            gsap.to(button, 0.1, {
              css: { transform: `translate(-50%, -50%) scale(0.9)` }
            })

            // gsap.to(buttonText, 0.1, {
            //   css: { opacity: 1 }
            // });

            //console.log("mousedown")


            //console.log(videoIsPlaying)
            if (videoIsPlaying) {
              player.setVolume(0)
            } else {
              player.setVolume(1)
            }
            videoIsPlaying = !videoIsPlaying
            updateDom(videoIsPlaying)


          })
          el.addEventListener("mouseup", () => {

            gsap.to(button, 0.1, {
              css: { transform: `translate(-50%, -50%) scale(1)` }
            })

            // gsap.to(buttonText, 0.1, {
            //   css: {
            //     opacity: 1
            //   }
            // });

          })
        })
      } else {
        const followArea = document.querySelectorAll(".full-page-video-section")
        followArea.forEach(function(el) {

          el.addEventListener("mousedown", () => {
            //console.log("mousedown")
            player.play()
            player.setVolume(1)
          })

          el.addEventListener("mouseup", () => {
            //console.log('mouseup');
          })
        })
      }
    }
  }, [initialize])

  return (
    <div className={padding}>
      <div className={"full-page-video-section video-section"}>
        <div className={"vimeo-embed"}>
          <iframe id={"vimeo-video-embed"}
                  title={"vimeo player"}
                  data-src={"https://player.vimeo.com/video/" + video + "?autoplay=0&title=0&playsinline=0&portrait=1&controls=0&loop=1"}
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                  frameBorder="0"
                  webkitallowfullscreen={"true"} mozallowfullscreen={"true"} allowFullScreen
                  allow="autoplay; fullscreen"
          />
          <MobileView>
            <div className={"toggle-play-button"}>
              <div className={"toggle-play-button-text"}>
                PLAY
              </div>
            </div>
          </MobileView>
        </div>
      </div>
      <BrowserView>
        <div className={"toggle-music-button"}>
          <div className={"toggle-music-button-text"}>
            {dom ? "MUTE" : "UNMUTE"}
          </div>
        </div>
      </BrowserView>
    </div>
  )
}

export default FullPageVide
